import React from 'react'
import Button from '@components/Button'
import Container from '@packages/justo-parts/lib/components/Container'
import Head from 'next/head'
import Link from 'next/link'

import styles from './styles.module.css'
export default function NotFound(props) {
  return <>
    <div className={styles.container}>
      <Container size="small">
        <h1>Esta página no existe</h1>
        <p>
          <Link href="/">

            <Button>Volver al inicio</Button>

          </Link>
        </p>
      </Container>
    </div>
    <Head>
      <meta name="robots" content="noindex" />
    </Head>
  </>;
}
